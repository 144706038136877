/**
    This is the Strategic Data Calendar view
    TECH DEBT: The x icons in the create/edit form are clickable. We should make a special button type to handle clickable icons for accessibility purposes.
*/
<template>
    <div id="calendar-app">
        <Sidebar>
            <div class="sidebar-section">
                <div class="sidebar-section-title">
                    Display Options
                </div>
                <div class="sidebar-section-padded">
                    <CustomSelect :options="modes" title="Show" :modelValue="mode" @update:modelValue="switchModes" />
                </div>
                <div class="sidebar-section-padded">
                    <CustomSelect :options="eventCategoryFilterOptions" title="" v-model="selectedCategoryFilter" />
                </div>
            </div>
            <div class="sidebar-section" v-if="mode==='Monthly View'">
                <div class="sidebar-section-title">
                    Select Month
                </div>
                <div class="sidebar-section-padded">
                    <CustomSelect :options="months" title="" v-model="selectedMonth" />
                </div>
            </div>
            <hr class="sidebar-division">
            <div class="legend">
                <header class="legend-title">Legend</header>
                <div><span class="legend-block legend-otc">&nbsp;</span>OTC</div>
                <div><span class="legend-block legend-data">&nbsp;</span>Data Team</div>
                <div><span class="legend-block legend-admin">&nbsp;</span>Administrator</div>
                <div><span class="legend-block legend-counseling">&nbsp;</span>Counseling Director</div>
            </div>
        </Sidebar>
        <div class="main-area-narrow main-area-indented">
            <div class="scroll-area" v-show="mode==='Yearly View'">
                <div class="page-title">
                    <h1 class="headline-medium contrast-full">
                        Grade {{ store.state.showingGradeLevel }} Strategic Data Calendar - Yearly View
                    </h1>
                    <span class="body-large contrast-higher">Academic Calendar</span>
                </div>

                <div class="calendar-container">
                    <div
                        v-for="(month, i) in months" :key="i" 
                        class="calendar-block"
                        @click="selectMonth(month.displayName)"
                    >
                        <div class="calendar-title">
                            {{ month.displayName }}
                        </div>
                        <div class="calendar-body">
                            <ul v-for="(event, j) in yearEvents" :key="j" class="calendar-task-list">
                                <li 
                                    v-if="event.months.includes(month.displayName)" 
                                    class="ellipsis" 
                                    :class="{'completed-task': isComplete({event, month: month.displayName})}"
                                    :style="getCategoryEventStyle(event)"
                                >
                                    {{ event.title }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <CustomButton 
                    v-if="$user.hasPower('writeToCalendar')" 
                    @click="launchCreateEvent()" 
                    buttonText="New Event" 
                />
            </div>
            <div class="scroll-area" v-show="mode==='Monthly View'">
                <div class="row headline-small">
                    <span 
                        class="material-icons navigation-arrow" 
                        @click="previousMonth()"
                        title="Previous Month"
                    >
                        navigate_before
                    </span>
                    <span class="contrast-full">{{ selectedMonth }} Strategic Data Calendar</span>
                    <span 
                        class="material-icons navigation-arrow" 
                        @click="nextMonth()"
                        title="Next Month"
                    >
                        navigate_next
                    </span>
                </div>
                <table class="monthly-view">
                    <tr class="header-row">
                        <th class="title-col">Data Protocol/Task</th>
                        <th v-if="$user.hasPower('writeToCalendar')">Actions</th>
                        <th class="description-col">Description</th>
                        <th class="toggle-col"></th>
                        <th v-if="$user.hasPower('markCalendarItemsComplete')" class="completed-col">Completed</th>
                    </tr>
                    <tr v-for="(event, j) in events" :key="j">
                        <td 
                            class="body-cell title-col" 
                            :class="{'completed-task': isComplete({event, month: selectedMonth})}"
                            :style="getCategoryEventStyle(event)"
                        >
                            {{ event.title }}
                        </td>
                        <td v-if="$user.hasPower('writeToCalendar')">
                            <span 
                                class="material-icons action-btn" 
                                @click="launchEdit(event)"
                                title="Edit Event"
                            >
                                edit
                            </span>
                            <span 
                                class="material-icons action-btn" 
                                @click="deleteEvent(event)"
                                title="Delete Event"
                            >
                                delete
                            </span>
                        </td>
                        <td class="body-cell description-col">
                            <div class="event-description" :class="{'completed-task': isComplete({event, month: selectedMonth})}">
                                {{ event.description }}
                            </div>
                            <div v-if="event.docID === expandedEvent">
                                <div v-if="event.links.length">
                                    <div class="subtitle">
                                        Linked Resources
                                    </div>
                                    <ul>
                                        <li v-for="(link, k) in event.links" :key="k" @click="redirect(link)">
                                            <span class="data-link" v-if="link.type === 'url'">{{ link.name ? link.name : 'External Link'}}</span>
                                            <span class="data-link" v-else-if="link.type === 'listView'">
                                                List: <span class="primary">{{ link.listView }}</span>
                                                <span v-if="link.keyInsight"> with <span class="primary">{{ link.keyInsight }}</span> insights</span>
                                            </span>
                                            <span class="data-link" v-else-if="link.type === 'protocol'">Protocol: {{ link.protocol }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="event.keyActions.length">
                                    <div class="subtitle">
                                        Key Actions
                                    </div>
                                    <ul>
                                        <li v-for="(action, k) in event.keyActions" :key="k">
                                            {{ action }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </td>
                        <td class="body-cell toggle-col">
                            <span 
                                class="material-icons-outlined clickable-area bigger"
                                @click="expandEvent({event})"
                            >
                                {{ isExpanded({event}) ? 'expand_less' : 'expand_more' }}
                            </span>

                        </td>
                        <td v-if="$user.hasPower('markCalendarItemsComplete')" class="body-cell completed-col">
                            <span 
                                class="material-icons-outlined"
                                @click="toggleComplete({event, month: selectedMonth})"
                                :class="$user.hasPower('markCalendarItemsComplete') ? 'clickable-area' : ''"
                            >
                                {{ isComplete({event, month: selectedMonth}) ? 'done' : 'check_box_outline_blank' }}
                            </span>
                        </td>
                    </tr>                    
                </table>
                <CustomButton 
                    v-if="$user.hasPower('writeToCalendar')" 
                    @click="launchCreateEvent()" 
                    buttonText="New Event" 
                />
            </div>
        </div>
    </div>

    <!-- Event create/update modal -->
    <Modal 
        ref="calendarCRUDModal" 
        class="event-modal"
        :fullscreen="true"
        :width="950"
        :title="create.mode=='edit' ? 'Update Event' : 'Create New Event'"
        :acceptButtonText="create.mode=='edit' ? 'Update' : 'Create'"
        :disableSuccess="formErrors.length ? true : false"
        @success="createOrUpdateEvent"
    >
        <form class="event-form" onsubmit="return false;">
            <div class="col half">
                <div class="col">
                    <label for="event-title" :class="'title-medium ' + required(create.title)">Title</label>
                    <input id="event-title" class="full" type="text" v-model="create.title" placeholder="Event Name" />
                </div>
                
                <div class="col">
                    <label for="event-description" :class="'title-medium ' + required(create.description)">Description</label>
                    <textarea id="event-description" class="full" v-model="create.description" rows="4" placeholder="Type here" />
                </div>

                <div class="col">
                    <label for="event-grade" :class="'title-medium ' + required(create.grade)">Grade</label>
                    <select id="event-grade" class="full" v-model="create.grade">
                        <option disabled selected hidden value="">Select a grade</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                </div>
                
                <div class="col">
                    <label for="event-category" class="title-medium">Category</label>
                    <select id="event-category" class="full" :value="create.eventCategories[0]" @input="create.eventCategories[0] = $event.target.value">
                        <option v-for="(category, key) in eventCategoryOptions" :key="key" :value="category.value">{{ category.title }}</option>
                    </select>
                </div>

                <span :class="'title-medium ' + required(create.months.length > 0)">Months</span>
                <div class="row">
                    <div class="month" v-for="(month, key) in months" :key="'monthLabel' + key">
                        <input :id="'month-checkbox' + key" type="checkbox" v-model="create.months" :value="month.displayName" @keyup.enter="monthCheckboxEnter(month.displayName, $event)"/>
                        <label :for="'month-checkbox' + key">                 
                            {{ month.displayName.slice(0, 3) }}
                        </label>
                    </div>
                </div>
            </div>

            <hr/>

            <div class="col half larger">
                <div class="col">
                    <span class="title-medium">Links</span>
                    <div class="links" v-for="(link, j) in create.links" :key="j">
                        <div class="row">
                            <span :class="required(isValid(create.links[j])) + ' list-num'">{{ j + 1 }}.</span>
                            <select v-model="create.links[j].type">
                                <option disabled selected hidden value="">Select a link type</option>
                                <option value="listView">List view</option>
                                <option value="protocol">Protocol</option>
                                <option value="url">URL</option>
                            </select>                                      
                            <select v-show="create.links[j].type=='protocol'" v-model="create.links[j].protocol">
                                <option disabled selected hidden value="">Select a Protocol</option>
                                <option v-for="(protocol, key) in availableProtocols" :key="key" :value="protocol">{{ protocol }}</option>
                            </select>
                            <button type="button" class="close-btn action-btn" @click="deleteLink(j)">✖</button>
                        </div>
                        <div class="col sub-option" v-show="create.links[j].type=='url'">
                            <input v-model="create.links[j].name" class="full" type="text" placeholder="Link Name"/>                                        
                            <input v-model="create.links[j].url" class="full" type="text" placeholder="e.g. docs.google.com/12345"/>
                        </div>
                        <div class="col sub-option" v-show="create.links[j].type=='listView'">
                            <select class="full" v-model="create.links[j].listView">
                                <option disabled selected hidden value="">Select a View</option>
                                <option v-for="(view, key) in availableViews" :key="key" :value="view">{{ view }}</option>
                            </select> 

                            <div class="sub-row" v-show="create.links[j].listView">
                                <input id="add-key-insight" type="checkbox" v-model="create.links[j].addKeyInsight" />
                                <label for="add-key-insight">Add a Key Insight?</label>
                            </div>

                            <select class="full" v-show="create.links[j].listView && create.links[j].addKeyInsight" v-model="create.links[j].keyInsight">
                                <option disabled selected hidden value="">Select a Key Insight</option>
                                <option v-for="(keyInsight, key) in keyInsights" :key="key" :value="keyInsight.name">{{ keyInsight.name }}</option>
                            </select> 
                        </div> 
                    </div>
                    <button type="button" class="text-btn icon small" @click="addLink()">
                        <span class="material-icons">add</span>
                        Add Link
                    </button>
                </div>

                <div class="col">
                    <span :class="'title-medium ' + required(create.keyActions.length)">Key Actions</span>
                    <div v-for="(keyAction, j) in create.keyActions" :key="j" class="row key-action">
                        <span :class="required(create.keyActions[j]) + ' list-num'">{{ j + 1 }}.</span>
                        <input v-model="create.keyActions[j]" type="text" placeholder="Type here">
                        <button type="button" class="close-btn action-btn" @click="deleteKeyAction(j)">✖</button>
                    </div>
                    <button type="button" class="text-btn icon small" @click="addKeyAction()">
                        <span class="material-icons">add</span>
                        Add Key Action
                    </button>
                </div>
            </div>
        </form>
    </Modal>
</template>

<script>
import { defaultViews } from '@/apps/views.js';
import { protocols } from '@/apps/protocols.js';
import { insights } from '@/functions/insight.js';
import Sidebar from '@/components/nav/sidebar.vue';
import CustomSelect from '@/components/customSelect.vue';
import CustomButton from '@/components/customButton.vue';
import Modal from '@/components/modals/modal.vue';

const months = [
    'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'
];

//need a regular calendar month order for selecting current month on mode change
const dateAPIMonths = ["January","February","March","April","May","June","July","August","September","October","November","December"];

const calendarEventCategories = [
    { 
        title: "OTC",
        value: "OTC",
        style: "warning-light"
    },
    { 
        title: "Data Team",
        value: "Data Team",
        style: "alert-light"
    },
    { 
        title: "Administrator",
        value: "Administrator",
        style: "info-light"
    },
    { 
        title: "Counseling Director",
        value: "Counseling Director",
        style: "success-light"
    },
    {
        title: "No Category",
        value: "",
        style: ""
    }
];

export default {
    components: {
        CustomButton,
        CustomSelect, 
        Modal,
        Sidebar,
    },
    data() {
        return {
            create: this.getEmptyEvent(),
            eventCategoryOptions: calendarEventCategories,
            expandedEvent: '',
            keyInsights: this.insightsAllGrades(),
            mode: 'Yearly View',
            modes: ['Yearly View', 'Monthly View'].map(e => ({displayName: e, value: e})),
            months: months.map(e => ({displayName: e})),
            selectedMonth: 'September',
            selectedCategoryFilter: 'All Events'
        }
    },
    inject: ['posthog'],
    methods: {
        isComplete({event, month}) {
            return this.stepsComplete.some(element => 
                element.id === event.docID &&
                element.month === month
            );
        },
        toggleComplete({event, month}) {
            if (!this.$user.hasPower('markCalendarItemsComplete')) return;
            if (!this.isComplete({event, month})) {
                this.setComplete({event, month});
            } else {
                this.setIncomplete({event, month});
            }
        },
        setComplete({event, month}) {
            this.stepsComplete.push({
                id: event.docID,
                month
            });
            this.updateSchoolCompletedSDCSteps();
        },
        setIncomplete({event, month}) {
            const completedSteps = this.stepsComplete.filter(element => !(
                element.id == event.docID &&
                element.month == month
            ));
            this.store.setCompletedSDCSteps(completedSteps);
            this.updateSchoolCompletedSDCSteps();
        },
        redirect(link) {
            const type = link.type;
            if (!type) return;
            if (type === 'url') {
                //add on just http instead of https. Most urls will auto redirect to https.
                const url = link.url.includes('://') ? link.url : `http://${link.url}`;
                window.open(url, '_blank');
            }
            if (type === 'protocol') {
                this.store.setCustomProp(link.protocol);
                this.$router.push('/protocols');
            }
            if (type === 'listView') {
                const view = defaultViews.find(v => v.name === link.listView);
                const keyInsight = link.keyInsight ?? null;

                //set custom view + key insight for going to Student Lists
                this.store.setCustomProp({
                    view: view,
                    keyInsight: keyInsight,
                })
                this.$router.push('/tracker');
            }
        },
        updateSchoolCompletedSDCSteps() {
            this.fb.schools.updateField({key: 'completedSDCSteps', newValue: this.stepsComplete});
        },
        expandEvent({event}) {
            this.expandedEvent = this.isExpanded({event}) ? '' : event.docID;
        },
        isExpanded({event}) {
            return this.expandedEvent === event.docID;
        },
        launchEdit(event) {
            this.create = event;
            this.create.mode = 'edit';
            this.$refs.calendarCRUDModal.show();
        },
        launchCreateEvent() {
            this.create = this.getEmptyEvent();
            this.create.mode = 'create';
            this.$refs.calendarCRUDModal.show();
        },
        getEmptyEvent() { 
            return {
                title: '',
                eventCategories: [],
                grade: '',
                description: '',
                months: [],
                links: [],
                keyActions: [],
                OTCEvent: false,
                mode: 'create'
            };
        },
        async createOrUpdateEvent() {
            // Update Firebase
            if (this.create.docID) {
                await this.fb.calendar.update(this.create.docID, {...this.create});
            } else {
                this.fb.calendar.add({...this.create});
            }

            const calendarEvents = await this.fb.calendar.getAll();
            this.store.setCalendarEvents(calendarEvents);
        },
        deleteEvent(eventArg) {
            this.store.setCalendarEvents(
                this.store.state.calendarEvents
                    .filter(event => event.docID !== eventArg.docID)
            );

            this.fb.calendar.delete(eventArg.docID);
        },
        required(str) {
            return str ? '' : 'field-required';
        },
        addKeyAction() {
            this.create.keyActions.push('');
        },
        deleteKeyAction(i) {
            this.create.keyActions.splice(i, 1);
        },
        addLink() {
            this.create.links.push({
                type: '',
                listView: '',
                keyInsight: '',
                protocol: '', 
                url: ''
            });
        },
        deleteLink(i) {
            this.create.links.splice(i, 1);
            this.addKeyInsight = false;
        },
        isValid(link) {
            if (!link.type) return false;
            if (link.type === 'url' && !link.url) return false;
            if (link.type === 'protocol' && !link.protocol) return false;
            if (link.type === 'listView' && !link.listView) return false;
            return true;
        },
        monthLevelClass(i, month) {
            return this.create.months.includes(month.displayName) ?
                'month-circle-active' : 
                'month-circle-selectable';
        },
        toggleMonth(i, month) {
            if (this.create.months.includes(month.displayName)) {
                this.create.months = this.create.months.filter(m => m !== month.displayName);
            } else {
                this.create.months.push(month.displayName);
            }
        },
        selectMonth(month) {
            this.selectedMonth = month;
            this.mode = 'Monthly View';
        },
        previousMonth() {
            const index = months.indexOf(this.selectedMonth);
            if (index === 0) {
                this.selectedMonth = months[11];
            } else {
                this.selectedMonth = months[index - 1];
            }
            
        },
        nextMonth() {
            const index = months.indexOf(this.selectedMonth);
            if (index === 11) {
                this.selectedMonth = months[0];
            } else {
                this.selectedMonth = months[index + 1];
            }
        },
        /**
         * Allows user to use the Enter key to select Month 
         * 
         * @param {string} month Name of the Month selected
         * @param {object} e     Default JS event
         */
        monthCheckboxEnter(month, e) {
            e.preventDefault();
            const index = this.create.months.indexOf(month);
            if (index !== -1) {
                this.create.months.splice(index, 1);
            } else {
                this.create.months.push(month);
            }
        },

        /**
         * Filter to find insights that will be included in all grades. Used mainly until a grade is selected for an event
         */
        insightsAllGrades() {
            return insights.filter(insight => [8, 9, 10, 11, 12].every(grade => insight.showInGrades.includes(grade)));
        },

        switchModes(value) {
            const date = new Date();
            this.selectedMonth = dateAPIMonths[date.getMonth()];
            this.mode = value;
        },
        /**
         * Gets styling for the color coded bar on the left side of SDC events
         * @param {Object} event an SDC calendar event object
         * Returns a style object for the template if there is an event category associated with the event. Returns no style if the event doesn't have a category selected
         */
        getCategoryEventStyle(event) {
            if (event.eventCategories.length > 0 && event.eventCategories[0] !== "") {
                const eventCategory = event.eventCategories[0];
    
                const matchingConfigEvent = calendarEventCategories.find(item => item.title === eventCategory);
                
                return { background: `linear-gradient(to right, var(--color-${matchingConfigEvent.style}) 4px, var(--color-secondary-container) 4px, var(--color-secondary-container))` }
            } else {
                return "";
            }
        }
    },
    computed: {
        events: function () {
            return this.store.state.calendarEvents
                .filter(e => e.title !== undefined)
                .filter(e => Number(e.grade) === this.store.state.showingGradeLevel)
                .filter(e => e.months.includes(this.selectedMonth))
                .filter(e => {
                    if (this.selectedCategoryFilter === 'All Events') return true;
                    if (this.selectedCategoryFilter === 'No Category') {
                        return !e.eventCategories[0] || e.eventCategories[0] === "";
                    }
                    if (e.eventCategories[0]) {
                        return e.eventCategories[0] === (this.selectedCategoryFilter);
                    }
                })
        },
        // Returns all of the Calendar Events for the specified grade to show by month in the yearly view
        yearEvents: function () {
            const eventsByGrade = this.store.state.calendarEvents
                .filter(e => e.title !== undefined)
                .filter(e => Number(e.grade) === this.store.state.showingGradeLevel)
                .filter(e => {
                    if (this.selectedCategoryFilter === 'All Events') return true;
                    if (this.selectedCategoryFilter === 'No Category') {
                        return !e.eventCategories[0] || e.eventCategories[0] === "";
                    }
                    if (e.eventCategories[0]) {
                        return e.eventCategories[0] === (this.selectedCategoryFilter);
                    }
                });
            return eventsByGrade;
        },
        availableViews: function () {
            return defaultViews
                .filter(e => e.grades.includes(this.store.state.showingGradeLevel))
                .map(e => e.name)
        },
        availableProtocols: function () {
            return Object.keys(protocols); // need to filter
        },
        formErrors: function () {
            const result = [];
            ['title', 'description', 'grade'].forEach(element => {
                if (!this.create[element]) result.push(`Event must have a ${element}.`);
            });

            if (!this.create.keyActions?.length) result.push(`Event must have at least one key action.`);

            this.create.keyActions.forEach(keyAction => {
                if (!keyAction) result.push(`Key action cannot be blank.`);
            });

            this.create.links.forEach(link => {
                if (!link.type) result.push(`Link must have a type.`);
                if (link.type === 'url' && !link.url) result.push(`Link must have a URL.`);
                if (link.type === 'protocol' && !link.protocol) result.push(`Link must have a protocol.`);
                if (link.type === 'listView' && !link.listView) result.push(`Link must have a list view.`);
            });

            if (!this.create.months.length) result.push('Event must have at least one month.');

            return result;
        },
        stepsComplete: function () {
            return this.store.state.schoolMetaData.completedSDCSteps;
        },
        // Uses calendarEventCategories config to create an array of filter options for a CustomSelect component
        eventCategoryFilterOptions: function () {
            const categoriesStringArray = calendarEventCategories.map(e => e.title);
            categoriesStringArray.unshift('All Events');
            const categoriesFilterArray = categoriesStringArray.map(e => ({ displayName: e, value: e }));

            return categoriesFilterArray;
        }
    },
    watch: {
        /**
         * When a new grade is selected, we want to update which Key Insights will be available
         * so that it will not break when navigating to the Student Lists.
         * @param {string} grade Grade being selected in event creation modal.
         */
        'create.grade'(grade) {
            //comes in as a string
            grade = parseInt(grade);
            
            //filter by grade
            this.keyInsights = grade ? insights.filter(insight => insight.showInGrades.includes(grade)) : this.insightsAllGrades();

            //clear selection
            this.create.links.forEach(link => {
                if(link.keyInsight) {
                    const insight = insights.find(insight => insight.name === link.keyInsight);
                    //keep the same or clear it out
                    link.keyInsight = insight.showInGrades.includes(grade) ? link.keyInsight : null;
                }
            });
        }
    },
    activated() {
        // Send pageview to posthog
        this.posthog.capture('$pageview', {
            $current_url: '/calendar'
        });
    }
}
</script>

<style lang="scss">
@use '../assets/styles/colors';
@use '../assets/styles/buttons';
@use '../assets/styles/variables/elevation';
@use '../assets/styles/variables/shape';
@use '../assets/styles/typography';
@use '../assets/styles/mixins/pieces';

.page-title {
    margin-left: 6px;
    & > * {
        margin: 0;
    }
}
.navigation-arrow {
    color: var(--color-primary);
    &:hover {
        cursor: pointer;
        color: var(--color-primary-light);
    }
}
.row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.action-btn {
    cursor: pointer;
    color: var(--color-contrast-high);
    &:hover, &:focus {
        color: var(--color-primary);
    }
    &:active {
        color: var(--color-primary-light);
    }
}

.monthly-view {
	border-collapse: collapse;
    width: 100%;
    margin-bottom: 16px;
    
    .clickable-area {
        &:hover {
            cursor: pointer;
            color: var(--color-primary);
        }
    }
    .bigger {
        font-size: 3rem;
    }
    .header-row {
        background-color: var(--color-primary);
        color: var(--color-bg);
        font-weight: bold;
    }
    .title-col {
        width: 20%
    }
    .toggle-col {
        width: 10%
    }
    .completed-col {
        width: 10%
    }
    .subtitle {
        font-weight: bold;
        margin-top: 1rem;
    }
    th {
        text-align: left;
        padding: 1rem;
    }
    td {
        vertical-align: top;
        padding: 1rem;
        border-bottom: 1px solid var(--color-contrast-full);
        background: var(--color-surface-1), var(--color-surface);
        color: var(--color-on-surface);
        
        &.title-col {
            font-weight: bold;
            background-color: var(--color-secondary-container);
            color: var(--color-on-secondary-container);
        }
    }
    .data-link {
        color: var(--color-secondary);
        text-decoration: underline;
        &:hover {
            cursor: pointer;
            color: var(--color-primary);
            font-weight: 900;
        }
    }
}

.calendar-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 6px;

    //MD3: Card specs
    //TECH DEBT: should be moved to its own component
    .calendar-block {
        cursor: pointer;
        width: 30%;
        padding: 10px 0;
        margin: 0 24px 24px 0;
        border-radius: shape.$medium;
        box-shadow: elevation.$lightStep1;
        background: var(--color-surface-1), var(--color-surface);

        .calendar-title {
            border-radius: shape.$medium shape.$medium 0 0;
            //on surface
            color: var(--color-on-surface);
            @include typography.title-large;
        }
        .calendar-task-list {
            padding: 0;

            li {
                display: block;
                border-radius: 4px;
                padding: 6px 8px;
                font-size: 14px;
                margin-bottom: 12px;
                background-color: var(--color-secondary-container);
                color: var(--color-on-secondary-container);
                box-shadow: elevation.$lightStep1;
            }
        }
        &:hover {
            background: var(--color-surface-2), var(--color-surface);
            box-shadow: elevation.$lightStep2;
        }
        & > * {
            padding: 0 16px;
        }
    }
}

.field-required {
    color: var(--color-warning);
    &::after {
        content: '*';
    }
}

.delete-col {
    cursor: pointer;
    &:hover {
        color: var(--color-warning);
    }
}

.event-modal{
    .modal-content {
        background-color: var(--color-bg);
    }
}

/**
* Tech Debt - this ticket took forever. I used flexbox for most of the layout of the 
* form partially because it started off being just a single panel in a modal. 
* In retrospect, it might have been better to used CSS Grid instead since halfway through coding I switched
* to two panels. I am not as brushed up in Grid layouts and it would have taken me even longer.
*/
.event-form {
    display: flex;
    padding: 20px 0;

    h4 {
        margin-bottom: 4px;
    }
    hr {
        margin: 20px;
    }
    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 16px;
        .row {
            margin-bottom: 0;
        }
    }
    .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;

        margin-bottom: 16px;
        width: 100%;

        .sub-row {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 4px;
        }
    }
    .half {
        flex: 0 0 430px;
    }
    .larger {
        //need this section to be larger. running into button issues.
        flex-basis: 436px;
    }
    .full {
        width: -webkit-fill-available;
        width: -moz-available;
    }
    .month {
        margin: 6px 6px;
    }
    label {
        //Tech Debt - modal should not be setting styling for content
        //don't know what all it would break if I removed it from the modal styling.
        //override modal styling for now with !important.
        padding-left: 0 !important;
    }
    input[type^="text"], textarea, select {
        @include typography.body-large;
        font-family: inherit;
        background-color: transparent;
        border: 1px solid var(--color-contrast-high);
        border-radius: shape.$extra-small;
        margin-right: 8px;
        padding: 0 12px;
        // 24px for arrow icon + 12px spacing between text and icon.
        padding-right: 34px;
    }
    input[type^="text"], select {
        height: 36px;
    }
    textarea {
        padding: 6px 12px;
    }
    select {
        cursor: pointer;
        @include pieces.downArrow;
    }

    //Month Circle checkboxes
    input[id^="month-checkbox"] {
        cursor: pointer;
        position: absolute;
        height: 50px;
        width: 50px;
        margin: 0;
        opacity: 0;

        &:hover + label, &:focus + label {
            outline: solid medium var(--color-primary-light);
        }
        &:checked + label {
            background-color: var(--color-primary);
            color: var(--color-bg);
        }
    }
    label[for^="month-checkbox"] {
        display: flex;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 0;
        background-color: var(--color-contrast-low);
        font-weight: bold;
        border-radius: 100%;
        justify-content: center;
    }
    .list-num {
        margin-right: 8px;
    }
    .links {
        width: 100%;
        .sub-option {
            margin-left: 24px;
            input[type^="text"], select {
                margin: 0 44px 8px 0;
            }
        }
    }
    .key-action {
        width: 100%;
        input {
            flex: 1;
        }
    }
    .close-btn {
        background: transparent;
        border: none;
        cursor: pointer;
    }
    #event-OTC-only, #add-key-insight {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
}

//TECH DEBT: normally a design system would be able to account for dark mode just through its color tokens
// but had to make a special exception because our colors are not fully fleshed out for dark mode.
[data-theme="dark"] {
    input[type^="text"], textarea, select {
        color: var(--color-contrast-medium);
    }

    //slight update in colors for change in dark mode bg color
    .calendar-container .calendar-block {
        background: var(--color-surface-3), var(--color-surface);
        &:hover {
            background: var(--color-surface-5), var(--color-surface);
        }
    }

    .monthly-view td {
        background: var(--color-surface-3), var(--color-surface);
        
        &.title-col {
            background-color: var(--color-secondary-container);
        }
    }
}

//Event create/update modal is a large form. Make the form a single column if browser is smaller than both columns.
@media only screen and (max-width: 1030px){
    .event-form{
        flex-direction: column;

        hr {
            display: none;
        }
        .half {
            flex: unset;
        }
    }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.completed-task {
    text-decoration: line-through;
}

.legend {
    margin: 16px 0 4px 4px;

    .legend-title {
        margin-bottom: 10px;
    }

    .legend-block {
        display: inline-block;
        width: 20px;
        margin: 2px 6px;
    }
    
    .legend-otc {
        background-color: var(--color-warning-light);
    }
    
    .legend-data {
        background-color: var(--color-alert-light);
    }
    
    .legend-admin {
        background-color: var(--color-info-light);
    }
    
    .legend-counseling {
        background-color: var(--color-success-light);
    }
}

</style>
